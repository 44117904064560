@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../../../../assets/scss/abstracts/functions";
@import "../../../../assets/scss/abstracts/variables";

.image-holder {
  @include media-breakpoint-down(md) {
    position: relative;
    height: 80vh;
    width: 100%;
    height: 30vh;
  }
}

.image {
  width: 46vw;
  position: absolute;
  top: 80px;
  right: 0;
  display: flex;
  align-items: center;
  height: calc(100% - 80px);
  @media screen and (max-width: 340px) {
    display: none;
  }
  @media screen and (max-width: 992px) and (min-width: 768px) {
    width: calc(46vw - 150px);
  }
  @include media-breakpoint-down(md) {
    right: -15px;
    top: 0;
    height: 33vh;
    width: calc(100vw - pxToRem(20));
  }
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 30vh;
    }
  }
}

.image-container {
  width: 46vw;
  position: absolute;
  top: 80px;
  right: 0;
  display: flex;
  align-items: center;
  height: calc(100% - 80px);
  @include media-breakpoint-down(md) {
    right: -15px;
    top: 0;
    height: 33vh;
    width: calc(100vw - pxToRem(20));
  }

  @media screen and (max-width: 340px) {
    display: none;
  }
  @media screen and (max-width: 992px) and (min-width: 768px) {
    width: calc(50vw - 100px);
  }

  > div {
    display: block;
    background-color: transparent;
    height: 500px;
    top: 0;
    background-image: radial-gradient(
        circle at center,
        white 15px,
        transparent 4px
      ),
      radial-gradient(circle at center, white 15px, transparent 4px);
    background-size: 40px 33px;
    background-position: center -20px, center calc(100% + 20px);
    background-repeat: repeat-x;
    width: 100%;
    z-index: 13;
    @include media-breakpoint-down(md) {
      background-image: radial-gradient(
          circle at center,
          white 10px,
          transparent 4px
        ),
        radial-gradient(circle at center, white 10px, transparent 4px);
      height: 30vh;
      background-size: 40px 40px;
    }
  }
}
